<template>
    <b-modal v-model="show"
             centered
             @hide="closeModal">
        <template #modal-title>
            <p class="title-text">新增裝備</p>
        </template>
        <div class="form-group">
            <label class="form-label label-text">裝備</label>
            <div class="d-flex equipment">
                <button v-model="form.equipment_type"
                        type="button"
                        class="btn"
                        :class="{'active':form.equipment_type === 'racket'}"
                        @click="form.equipment_type = 'racket'">
                    球拍
                </button>
                <!--                <button v-model="form.equipment_type"-->
                <!--                        type="button"-->
                <!--                        class="btn"-->
                <!--                        :class="{'active':form.equipment_type === 'shoe'}"-->
                <!--                        @click="form.equipment_type = 'shoe'">-->
                <!--                    球鞋-->
                <!--                </button>-->
            </div>
        </div>
        <div class="form-group">
            <label class="form-label label-text">{{ form.equipment_type === 'racket' ? '球拍' : '球鞋' }}</label>
            <common-select v-model="form.equipment_id"
                           :select-list="form.equipment_type === 'racket' ? racketList:form.equipment_type === 'shoe' ? shoeList : []"
                           name="equipment"
                           :class="{'error-control':errors.has('equipment:required')}"
                           :is-search="true"
                           find-key="id"
                           display-label="name"
                           v-validate="'required'"/>
        </div>
        <div v-if="form.equipment_type === 'racket'" class="form-group">
            <label class="form-label label-text">感測器</label>
            <common-select v-model="form.sensor_id"
                           :select-list="sensorList"
                           name="sensor"
                           key="sensor"
                           :class="{'error-control':errors.has('sensor:required')}"
                           :is-search="true"
                           display-label="mac_address"
                           find-key="id"
                           :is-disabled="isFetching || !form.equipment_id"
                           :is-return-object="true"
                           :return-object.sync="form.sensor"
                           v-validate="'required'"/>
        </div>
        <template v-else>
            <div class="form-group">
                <label class="form-label label-text">感測器(左腳)</label>
                <common-select v-model="form.left_sensor_id"
                               :select-list="sensorList"
                               key="left_sensor"
                               name="left_sensor"
                               :class="{'error-control':errors.has('left_sensor')}"
                               :is-search="true"
                               display-label="mac_address"
                               find-key="id"
                               :is-disabled="isFetching || !form.equipment_id"
                               :is-return-object="true"
                               :return-object.sync="form.left_sensor"
                               v-validate="'required|customConfirmed:right_sensor_id'"/>
            </div>
            <div class="form-group">
                <label class="form-label label-text">感測器(右腳)</label>
                <common-select v-model="form.right_sensor_id"
                               :select-list="sensorList"
                               key="right_sensor"
                               name="right_sensor"
                               :class="{'error-control':errors.has('right_sensor')}"
                               :is-search="true"
                               display-label="mac_address"
                               find-key="id"
                               :is-disabled="isFetching || !form.equipment_id"
                               :is-return-object="true"
                               :return-object.sync="form.right_sensor"
                               v-validate="'required|customConfirmed:left_sensor_id'"/>
            </div>
        </template>
        <!--        <div class="form-group">-->
        <!--            <label class="form-label label-text">產品編號(序號)</label>-->
        <!--            <input v-model="form.product_no"-->
        <!--                   type="text"-->
        <!--                   name="product_no"-->
        <!--                   class="form-control"-->
        <!--                   disabled/>-->
        <!--        </div>-->
        <div class="form-group">
            <label class="form-label label-text">購買日期</label>
            <date-picker v-model="form.purchase_date"
                         name="purchase_date"
                         value-type="format"
                         format="YYYY-MM-DD"/>
        </div>
        <div class="form-group mb-0">
            <label class="form-label label-text">備註</label>
            <input v-model="form.equipment_tag"
                   type="text"
                   class="form-control"
                   placeholder="請輸入"/>
        </div>
        <template #modal-footer>
            <button type="button" class="btn gray-btn" @click="closeModal">取消</button>
            <button type="button" class="btn green-btn" @click="confirmHandler" :disabled="isLoading">
                <clip-loader v-if="isLoading" class="loading" color="white" size="15px"/>
                <span v-else>新增</span>
            </button>
        </template>
    </b-modal>
</template>
<script>
import CommonSelect from '@/components/Common/CommonSelect';

export default {
    name: 'EquipmentSensorModal',
    props: {
        isLoading: Boolean,
        value: {
            type: Boolean,
            default: true
        },
        racketList: Array,
        shoeList: Array
    },
    components: {
        CommonSelect
    },
    watch: {
        value(newVal) {
            this.show = newVal;
        },
        'form.equipment_type'(newVal) {
            this.form = {
                equipment_type: newVal,
                ...(this.form.product_no ? {product_no: this.form.product_no} : {}),
                ...(this.form.equipment_tag ? {equipment_tag: this.form.equipment_tag} : {}),
                ...(this.form.purchase_date ? {purchase_date: this.form.purchase_date} : {})
            };
        },
        'form.equipment_id'(newVal) {
            if(newVal) {
                this.fetchSensorList();
            }
        },
        'form.left_sensor_id'() {
            if(this.form.right_sensor_id) {
                this.$validator.validate('right_sensor');
            }
        },
        'form.right_sensor_id'() {
            if(this.form.left_sensor_id) {
                this.$validator.validate('left_sensor');
            }
        }
    },
    data() {
        return {
            isFetching: false,
            show: this.value,
            sensorList: [],
            form: {
                equipment_type: 'racket'
            }
        };
    },
    created() {
        this.$validator.extend('customConfirmed', {
            getMessage: field => `The selected ${field} cannot be the same`,
            validate: (value, [confirmedField]) => {
                return value !== this.form[confirmedField];
            }
        });
    },
    methods: {
        async fetchSensorList() {
            try {
                this.isFetching = true;
                const res = await this.$http.get('/sensor', {
                    params: {
                        enable: 1,
                        equipment_type: this.form.equipment_type === 'racket' ? 1 : 2
                    }
                });
                if(res.status === 200) {
                    this.sensorList = res.data.data;
                }
            } catch(err) {
                this.toastError('感測器清單獲取失敗，請稍後再試');
            } finally {
                this.isFetching = false;
            }
        },
        closeModal() {
            this.form = {
                equipment_type: 'racket'
            };

            this.$emit('input', false);
        },
        async confirmHandler() {
            const isPass = await this.validate(true);
            if(!isPass) return;

            const form = this.form;
            const data = {
                equipment_type: form.equipment_type,
                equipment_id: form.equipment_id,
                equipment_tag: !!form.equipment_tag ? form.equipment_tag : null,
                product_no: form.product_no,
                purchase_date: form.purchase_date,
                mac_address: form.equipment_type === 'racket' ? [form.sensor.mac_address] : [form.left_sensor.mac_address, form.right_sensor.mac_address]
            };

            this.$emit('add-equipment-sensor', data);
        }
    }
};
</script>
<style lang="scss" scoped>
.loading{
    padding: 4px 8px;
}
:deep(.modal-dialog){
    width: 400px;
    @media(max-width: 575px){
        width: calc(100% - 30px);
    }
}
.equipment{
    gap: 10px;
    .btn{
        flex:             1;
        background-color: white;
        border:           1px solid var(--primary-color);
        color:            #333;
        &:hover,
        &.active{
            background-color: var(--primary-color);
            color:            white;
        }
    }
}
</style>
