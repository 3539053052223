<template>
    <section>
        <div class="d-flex flex-wrap align-items-start justify-content-between">
            <search-bar :search-form="searchForm" :search-types="['裝備MAC','使用者名稱']" @search="searchHandle"/>
            <button type="button" class="btn blue-btn mb-3" @click="addEquipmentSensor">新增裝備</button>
        </div>
        <table class="table table-bordered">
            <thead>
            <tr>
                <th>裝備MAC</th>
                <th>裝備類型</th>
                <th>使用者名稱</th>
                <th>綁定帳號</th>
                <th>數據顯示於</th>
                <th>操作</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="isLoading || isFetching">
                <td colspan="6">
                    <clip-loader class="loading p-0" color="gray" size="30px"/>
                </td>
            </tr>
            <template v-else-if="(!isLoading || !isFetching) &&　equipmentSensorList.length !== 0">
                <tr v-for="(item,index) in equipmentSensorList" :key="item['mac_address']">
                    <td data-label="球拍MAC">{{ item.sensor ? item.sensor.mac_address : '' }}</td>
                    <td data-label="裝備類型">{{ item.equipment_type === 'racket' ? '球拍' : item.sensor_type === 1 ? '球鞋-左腳' : '球鞋-右腳' }}</td>
                    <td data-label="使用者名稱">
                        <common-select v-model="item.latest_equipment_sensor_record_user_id"
                                       :select-list="userList"
                                       :is-allow-empty="false"
                                       :row-sort="index"
                                       display-label="name"
                                       @input="userHandle"
                                       find-key="user_id"
                                       :is-search="true"
                                       :is-disabled="item.isDisabled"
                                       :is-return-object="true"
                                       :return-object.sync="item.selectedUser"/>
                    </td>
                    <td data-label="綁定帳號">
                        {{ item.selectedUser ? item.selectedUser.user.email : '無' }}
                    </td>
                    <td data-label="數據顯示於">
                        <common-select v-model="item['field_id']"
                                       :select-list="fieldList"
                                       find-key="id"
                                       :row-sort="index"
                                       display-label="name"
                                       :is-search="false"
                                       :is-allow-empty="false"
                                       @input="displayHandle"
                                       :is-disabled="item.isDisabled"/>
                    </td>
                    <td>
                        <div class="operate">
                            <button type="button" class="btn blue-btn" :class="{'gray-btn':!item.isDisabled}" @click="edit(item,index)" :disabled="item.isSaving">
                                <span v-if="item.isDisabled">編輯</span>
                                <span v-else>取消</span>
                            </button>
                            <button v-if="item.isDisabled" type="button" class="btn orange-btn" @click="save('reset',item)">
                                <clip-loader v-if="item.isSaving" class="p-0 loading" color="white" size="22px"/>
                                <span v-else>重設</span>
                            </button>
                            <button v-if="!item.isDisabled" type="button" class="btn green-btn" @click="save('save',item)">
                                <clip-loader v-if="item.isSaving" class="p-0 loading" color="white" size="22px"/>
                                <span v-else>儲存</span>
                            </button>
                            <!--                            <button type="button" class="btn red-btn" @click="delModalHandle(item.id)" :disabled="item.isSaving">-->
                            <!--                                刪除-->
                            <!--                            </button>-->
                        </div>
                    </td>
                </tr>
            </template>
            <tr v-else>
                <td colspan="6">
                    <p class="text-center">無球拍資料</p>
                </td>
            </tr>
            </tbody>
        </table>
        <!--        分頁-->
        <pagination v-model="pagination.currentPage"
                    :pagination="pagination"
                    @page-handle="fetchEquipmentSensorList"/>
        <!--        新增裝備modal-->
        <equipment-sensor-modal v-model="equipmentSensorModal.show"
                                :is-loading="isSubmitting"
                                :racket-list="racketList"
                                :shoe-list="shoeList"
                                @add-equipment-sensor="fetchAddEquipmentSensor"/>
        <!--        刪除裝備modal-->
        <common-modal v-model="delModal.show"
                      :has-close="true"
                      :is-loading="isSubmitting"
                      @input="delIdReset"
                      @confirm-handle="delEquipmentSensor">
            <template v-slot:modal-message>確定刪除此裝備?</template>
            <template v-slot:modal-btn>確認</template>
        </common-modal>
    </section>
</template>
<script>
import CommonSelect from '@/components/Common/CommonSelect';
import CommonModal from '@/components/Common/CommonModal';
import EquipmentSensorModal from '@/components/EquipmentSensorMgmt/EquipmentSensorModal.vue';
import Pagination from '@/components/Common/Pagination';
import SearchBar from '@/components/Common/SearchBar';
import {mapActions, mapState, mapGetters} from 'vuex';

export default {
    name: 'EquipmentSensorMgmt',
    components: {
        CommonModal,
        CommonSelect,
        EquipmentSensorModal,
        Pagination,
        SearchBar
    },
    computed: {
        ...mapState({
            userList: state => state.user.userList
        }),
        ...mapGetters({
            gymId: 'user/gymId'
        })
    },
    data() {
        return {
            isLoading: false, //讀取資料loading
            isFetching: false, //單一api loading
            isSubmitting: false, //重設、刪除loading
            searchForm: {},
            racketList: [],
            shoeList: [],
            equipmentSensorList: [],
            fieldList: [],
            tempUser: {
                userId: null,
                fieldId: null
            },
            pagination: {
                currentPage: 1,
                perPage: 10,
                totalPage: 1
            },
            equipmentSensorModal: {
                show: false
            },
            delModal: {
                show: false,
                delId: null
            }
        };
    },
    mounted() {
        this.fetchData();
    },
    methods: {
        ...mapActions('user', ['fetchUserList']),
        async fetchData() {
            this.isLoading = true;
            await this.fetchUserList();
            await this.fetchRacketList();
            // await this.fetchShoeList();
            await this.fetchFieldList();
            await this.fetchEquipmentSensorList();
            this.isLoading = false;
        },
        // 球拍清單
        async fetchRacketList() {
            try {
                const res = await this.$http.get('/racket');
                if(res.status === 200) {
                    this.racketList = res.data.data;
                }
            } catch(err) {
                this.toastError('球拍清單獲取失敗，請稍後再試');
            }
        },
        // 球鞋清單
        async fetchShoeList() {
            try {
                const res = await this.$http.get('/shoe');
                if(res.status === 200) {
                    this.shoeList = res.data.data;
                }
            } catch(err) {
                this.toastError('球鞋清單獲取失敗，請稍後再試');
            }
        },
        // 裝備清單
        async fetchEquipmentSensorList() {
            try {
                this.isFetching = !this.isLoading ?? false;
                const res = await this.$http.get('/equipmentSensor', {
                    params: {
                        per_page: 10,
                        page: this.pagination.currentPage,
                        enable: 1,
                        mac_address: this.searchForm.mac_address && this.searchForm.mac_address !== '' ? this.searchForm.mac_address : null,
                        user_name: this.searchForm.user_name && this.searchForm.user_name !== '' ? this.searchForm.user_name : null
                    }
                });
                if(res.status === 200) {
                    const data = res.data.data;
                    this.pagination.totalPage = data.last_page;
                    this.equipmentSensorList = _.map(data.data, item => {
                        item.latest_equipment_sensor_record_user_id = item && item.latest_equipment_sensor_record ? item.latest_equipment_sensor_record.user_id : null;
                        item.field_id = item && item.latest_equipment_sensor_record ? item.latest_equipment_sensor_record.field_id : null;
                        item.isSaving = false;
                        item.isDisabled = true;
                        return item;
                    });
                }
            } catch(err) {
                this.toastError('裝備清單獲取失敗，請稍後再試');
            } finally {
                this.isFetching = false;
            }
        },
        async fetchFieldList() {
            try {
                const res = await this.$http.get('/field', {
                    params: {
                        gym_id: this.gymId
                    }
                });
                if(res.status === 200) {
                    const list = res.data.data;
                    list.unshift({id: null, name: '不顯示'});
                    this.fieldList = _.filter(list, item => item.is_display !== 0);
                }
            } catch(err) {
                this.toastError('場地清單獲取失敗，請稍後再試');
            }
        },
        // 搜尋
        searchHandle(searchData) {
            this.searchForm = _.cloneDeep(searchData);
            this.pagination.currentPage = 1;
            this.fetchEquipmentSensorList();
        },
        // 開啟新增裝備modal
        addEquipmentSensor() {
            const permissionCheck = this.permissionCheck('新增擁有者裝備清單');
            if(!permissionCheck) return;

            this.equipmentSensorModal.show = true;
        },
        // 新增裝備
        async fetchAddEquipmentSensor(data) {
            try {
                this.isSubmitting = true;
                const res = await this.$http.post('/equipmentSensor', data);
                if(res.status === 200) {
                    this.toastSuccess('新增成功');
                    this.searchForm = {};
                    this.equipmentSensorModal.show = false;
                    this.pagination.currentPage = 1;
                    await this.fetchEquipmentSensorList();
                }
            } catch(err) {
                const errMsg = err.response.data;
                const usedMsg = 'The selected sensor id already used.';
                const productMsg = 'Product no already exists.';
                if(errMsg.product_no && errMsg.product_no.includes(productMsg)) {
                    this.toastError('已存在相同產品編號(序號)');
                }

                if(errMsg['mac_address.0'] || errMsg['mac_address.1']) {
                    if(data.equipment_type === 'racket' && errMsg['mac_address.0'].includes(usedMsg)) {
                        this.toastError('感測器已被其他裝備綁定，請先移除裝備後再試');
                    } else if(data.equipment_type === 'shoe') {
                        if(errMsg['mac_address.0'] && errMsg['mac_address.0'].includes(usedMsg)) {
                            this.toastError('左腳感測器已被其他裝備綁定，請先移除裝備後再試');
                        }
                        if(errMsg['mac_address.1'] && errMsg['mac_address.1'].includes(usedMsg)) {
                            this.toastError('右腳感測器已被其他裝備綁定，請先移除裝備後再試');
                        }
                    }
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        // 編輯時暫存原始資料處理
        edit(item, index) {
            const permissionCheck = this.permissionCheck('新增裝備使用紀錄表');
            if(!permissionCheck) return;

            if(item.isDisabled) {
                this.tempUser.userId = item.latest_equipment_sensor_record_user_id ? item.latest_equipment_sensor_record_user_id : null;
                this.tempUser.fieldId = item.field_id ? item.field_id : null;
                item.isDisabled = false;
            } else {
                this.equipmentSensorList[index].latest_equipment_sensor_record_user_id = this.tempUser.userId;
                this.equipmentSensorList[index].field_id = this.tempUser.fieldId;
                item.isDisabled = true;
                this.tempInit();
            }
        },
        // 選擇使用者
        userHandle(emitData) {
            if(emitData.item) {
                this.equipmentSensorList[emitData.rowSort].latest_equipment_sensor_record_user_id = emitData.item.user_id;
            } else {
                this.equipmentSensorList[emitData.rowSort].latest_equipment_sensor_record_user_id = null;
            }
        },
        // 選擇數據顯示
        displayHandle(emitData) {
            this.equipmentSensorList[emitData.rowSort]['field_id'] = emitData.item ? emitData.item.id : null;
        },
        // 編輯或重設裝備
        async save(type, item) {
            const permissionCheck = this.permissionCheck('新增裝備使用紀錄表');
            if(!permissionCheck) return;

            const userId = type === 'reset' ? item.user_id : item['latest_equipment_sensor_record_user_id'];
            const fieldId = type === 'reset' ? null : item['field_id'];

            const data = {
                gym_id: this.gymId,
                user_id: userId,
                field_id: fieldId,
                equipment_sensor_id: item.id
            };
            try {
                item.isSaving = true;
                const res = await this.$http.post('/equipmentSensorRecord', data);
                if(res.status === 200) {
                    if(item) {
                        this.toastSuccess();
                    }
                    if(type === 'reset') {
                        item.latest_equipment_sensor_record_user_id = item.user_id;
                        item.field_id = null;
                    }
                    item.isDisabled = true;
                    this.tempInit();
                }
            } catch(err) {
                this.toastError('儲存失敗，請稍後再試');
            } finally {
                item.isSaving = false;
            }
        },
        // 開啟刪除裝備modal
        delModalHandle(id) {
            const permissionCheck = this.permissionCheck('移除擁有者裝備清單');
            if(!permissionCheck) return;

            this.delModal.delId = id;
            this.delModal.show = true;
        },
        delIdReset() {
            this.delModal.delId = null;
        },
        // 刪除裝備
        async delEquipmentSensor() {
            try {
                this.isSubmitting = true;
                const res = await this.$http.delete(`/equipmentSensor/${this.delModal.delId}`);
                if(res.status === 200) {
                    this.toastSuccess('刪除成功');
                    this.delModal.show = false;
                    this.pagination.currentPage = 1;
                    await this.fetchEquipmentSensorList();
                }
            } catch(err) {
                this.toastError('刪除失敗，請稍後再試');
            } finally {
                this.isSubmitting = false;
            }
        },
        // 初始化暫存使用者及數據顯示
        tempInit() {
            this.tempUser = {
                userId: null,
                fieldId: null
            };
        }
    }
};
</script>
<style lang="scss" scoped>
@mixin mobile{
    @media(max-width: 991px){
        @content;
    }
}
.table{
    &.table-bordered{
        @include mobile{
            border: 0;
        }
    }
    thead{
        @include mobile{
            display: none;
        }
        th{
            &:nth-child(3){
                width: 200px;
            }
            &:nth-child(5){
                width: 200px;
            }
            &:last-child{
                width: 165px;
            }
        }
    }
    tr{
        @include mobile{
            display:       block;
            margin-bottom: 30px;
            border:        1px solid #ccc;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    td{
        @include mobile{
            display: block;
            width:   100% !important;
            border:  0;
        }
        &:before{
            @include mobile{
                content:       attr(data-label);
                position:      relative;
                display:       block;
                margin-bottom: 4px;
                font-weight:   bold;
            }
        }
    }
}
</style>
