<template>
    <b-modal v-model="show"
             centered
             @hide="closeModal">
        <template #modal-title>
            <slot name="modal-title">
                <p class="title-text">提示</p>
            </slot>
        </template>
        <slot name="modal-message"></slot>
        <template #modal-footer>
            <button v-if="hasClose" type="button" class="btn red-btn" @click="closeModal">關閉</button>
            <button v-else type="button" class="btn gray-btn" @click="closeModal">取消</button>
            <button type="button" class="btn green-btn" @click="confirmHandle">
                <clip-loader v-if="isLoading" class="loading py-1 px-2" color="white" size="15px"/>
                <slot v-else name="modal-btn">確定</slot>
            </button>
        </template>
    </b-modal>
</template>
<script>
import CommonSelect from '../Common/CommonSelect';

export default {
    name: 'CommonModal',
    props: {
        isLoading: Boolean,
        hasClose: {
            type: Boolean,
            default: false
        },
        value: {
            type: Boolean,
            default: true
        }
    },
    components: {
        CommonSelect
    },
    watch: {
        value(newVal) {
            this.show = newVal
        }
    },
    data() {
        return {
            show: this.value
        }
    },
    methods: {
        closeModal() {
            this.$emit('input', false)
        },
        confirmHandle() {
            this.$emit('confirm-handle', true)
        }
    }
}
</script>
<style lang="scss" scoped>
:deep(.modal-dialog){
    width: 400px;
    @media(max-width: 575px){
        width: calc(100% - 30px);
    }
}
</style>
