import moment from 'moment';
import store from '@/store/index';
import {mapGetters} from 'vuex';
import http from '@/config/http';
import Vue from 'vue';

const methods = {
    permissionCheck(permissions) {
        let $store = null;
        if(typeof this.$store !== 'undefined')
            $store = this.$store;
        else
            $store = store;

        let isPass = false;

        if(Array.isArray(permissions)) {
            _.forEach(permissions, item => {
                if(!isPass) return;
                isPass = this.permissions.includes(item) ? true : false;
            });
        } else {
            isPass = this.permissions.includes(permissions);
        }

        if(!isPass) {
            alert('無操作權限');
        }

        return isPass;
    },
    dateFormat(date, format) {
        if(!date) return null;

        return moment(date).format(format);
    },
    mathRound(val) {
        return !isNaN(val) ? Math.round(val * 10) / 10 : val;
    },
    toastSuccess(msg = '更新成功') {
        this.$toast.success({
            title: '成功訊息',
            message: msg
        });
    },
    toastError(msg = '儲存失敗') {
        this.$toast.error({
            title: '失敗訊息',
            message: msg
        });
    },
    commonToastError(errStatus) {
        let $store = null;
        if(typeof this.$store !== 'undefined')
            $store = this.$store;
        else
            $store = store;

        switch(errStatus) {
            case 401:
                this.$toast.error({
                    title: '失敗訊息',
                    message: '請重新登入'
                });
                localStorage.removeItem('token');
                $store.commit('user/UPDATE_USER', null);
                this.$router.push('/login');
                break;
            case 403:
                this.$toast.error({
                    title: '失敗訊息',
                    message: '您沒有權限'
                });
                break;
            case 400:
                this.$toast.error({
                    title: '失敗訊息',
                    message: '欄位驗證錯誤，請確認是否填寫正確'
                });
                break;
            default:
                this.$toast.error({
                    title: '失敗訊息',
                    message: '操作失敗，請稍後再試'
                });
        }
    },
    async validate(isInModal) {
        const isPass = await this.$validator.validateAll();
        const items = this.$validator.errors.items;

        this.$nextTick(async() => {
            if(items.length === 0) return;

            const modal = document.querySelector('.modal-open');
            const body = document.body;

            const container = isInModal ? modal : body;
            const offsetTop = isInModal ? body.clientHeight * 0.25 : 250;
            const scrollEl = document.querySelector(`[name="${items[0].field}"]`);

            await container.scrollTo({
                top: scrollEl.offsetTop - offsetTop,
                behavior: 'smooth'
            });

            scrollEl.focus();
        });

        return isPass;
    },
    // 驗證重置
    resetValidate() {
        this.$validator.pause();
        this.$nextTick(() => {
            this.$validator.errors.clear();
            this.$validator.fields.items.forEach(field => field.reset());
            this.$validator.fields.items.forEach(field => this.errors.remove(field));
            this.$validator.resume();
        });
    },
    async syncUser() {
        let $store = null;
        if(typeof this.$store !== 'undefined')
            $store = this.$store;
        else
            $store = store;
        try {
            const res = await http.get('/info');
            if(res.status === 200) {
                const data = res.data.data;
                $store.commit('user/UPDATE_USER', data.user);
            }
        } catch(err) {
            localStorage.removeItem('token');
            $store.commit('user/UPDATE_USER', null);
        }
    },
    async syncCsrfToken() {
        let $store = null;
        if(typeof this.$store !== 'undefined')
            $store = this.$store;
        else
            $store = store;

        let res = await http.get('/refreshCsrfToken');
        res = res.data;
        const data = res.data;
        const csrfToken = data.csrfToken;
        http.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken;
        $store.commit('UPDATE_TOKEN', csrfToken);
    },
    async exportFile(url, paramsData = null, fileName = null) {
        try {
            const res = await http.get(url, {
                responseType: 'blob',
                params: {
                    ...paramsData
                }
            });
            if(res.status === 200) {
                const hasDownload = document.querySelector('[data-download="download"]');
                if(hasDownload) {
                    document.body.removeChild(hasDownload);
                }
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement('a');
                const file_name = fileName ? fileName : decodeURI(res.headers["content-disposition"].split(" ")[2].replace("filename*=utf-8''", ""));
                link.href = url;
                link.setAttribute('data-download', 'download');
                link.setAttribute('download', file_name);
                document.body.appendChild(link);
                link.click();
            }
        } catch(err) {
            let reader = new FileReader();
            reader.readAsText(err.response.data, 'UTF-8');
            reader.onload = () => {
                const errData = JSON.parse(reader.result);
                if(errData.message) {
                    this.$toast.error({
                        title: ' 錯誤訊息',
                        message: errData.message
                    });
                }
            };
        }
    }
};

Vue.mixin({
    computed: {
        ...mapGetters({
            isLogin: 'user/isLogin',
            permissions: 'user/permissions'
        })
    },
    methods: methods
});

export default methods;
