import store from "@/store/index";
import mixin from "@/config/mixin";

export default async(to, from, next) => {
    store.commit('UPDATE_PAGE_LOADING', true);
    await mixin.syncUser();
    const isLogin = store.getters['user/isLogin'];
    store.commit('UPDATE_PAGE_LOADING', false);

    if(to.matched.some(record => record.meta.auth)) {
        if(isLogin) {
            const permission = store.getters['user/permissions'];
            _.forEach(to.matched, item => {
                const meta = item.meta;
                if(!meta.permissions) {
                    next();
                } else {
                    _.forEach(meta.permissions, item => {
                        if(permission.includes(item)) {
                            next();
                        } else {
                            alert('您無查看權限');
                            next('/admin');
                        }
                    })
                }
            })
        } else {
            localStorage.removeItem('token');
            store.commit('user/UPDATE_USER', null);
            next('/login');
        }
    } else {
        if(isLogin) {
            next('/admin/sensor');
        } else {
            next();
        }
    }
}
