<template>
    <section>
        <div class="d-flex flex-wrap align-items-start justify-content-between">
            <search-bar :search-form="searchForm" :search-types="['姓名']" @search="searchHandle"/>
            <div class="d-flex flex-wrap align-items-start">
                <button type="button" class="btn blue-btn mb-3 mr-2" @click="userModalHandle()">新增會員</button>
                <b-dropdown text="批次匯入" variant="dropdown">
                    <b-dropdown-item-button @click="importFileClick" :disabled="isSubmitting">excel匯入</b-dropdown-item-button>
                    <input ref="importFileButton" type="file" class="file-control" accept=".xls, .xlsx" @change="importFile"/>
                    <b-dropdown-item-button @click="exportFileClick" :disabled="isSubmitting">空白表單下載</b-dropdown-item-button>
                </b-dropdown>
            </div>
        </div>
        <div class="table-responsive">
            <table class="table table-bordered">
                <thead>
                <tr>
                    <th>姓名</th>
                    <th>出生日期</th>
                    <th class="email-th">Email(帳號)</th>
                    <th>性別</th>
                    <th>身高</th>
                    <th>體重</th>
                    <th>慣用手</th>
                    <th>慣用腳</th>
                    <th>建立時間</th>
                    <th>操作</th>
                </tr>
                </thead>
                <tbody>
                <tr v-if="isLoading">
                    <td colspan="10">
                        <clip-loader class="loading p-0" color="gray" size="30px"/>
                    </td>
                </tr>
                <template v-else-if="!isLoading && userList.length !== 0">
                    <tr v-for="item in userList" :key="item['mac_address']">
                        <td data-label="姓名">{{ item.name }}</td>
                        <td data-label="出生日期">{{ dateFormat(item.birthday, 'YYYY-MM-DD') }}</td>
                        <td data-label="Email(帳號)">{{ item.user ? item.user.email : '' }}</td>
                        <td data-label="性別">{{ item.gender !== null ? item.gender === 1 ? '男' : '女' : '' }}</td>
                        <td data-label="身高">{{ item.height }}</td>
                        <td data-label="體重">{{ item.weight }}</td>
                        <td data-label="慣用手">{{ item.dominant_hand !== null ? item.dominant_hand === 1 ? '右手' : '左手' : '' }}</td>
                        <td data-label="慣用腳">{{ item.dominant_foot !== null ? item.dominant_foot === 1 ? '右腳' : '左腳' : '' }}</td>
                        <td data-label="建立時間">{{ dateFormat(item['created_at'], 'YYYY-MM-DD') }}</td>
                        <td>
                            <div class="operate">
                                <button type="button" class="btn blue-btn" @click="userModalHandle(item)">
                                    編輯
                                </button>
                                <button type="button" class="btn red-btn" @click="delModalHandle(item.id)">
                                    刪除
                                </button>
                            </div>
                        </td>
                    </tr>
                </template>
                <tr v-else>
                    <td colspan="10" class="text-center">無會員資訊</td>
                </tr>
                </tbody>
            </table>
        </div>
        <!--        分頁-->
        <pagination v-model="pagination.currentPage"
                    :pagination="pagination"
                    @input="pageHandler"/>
        <user-modal v-model="userModal.show"
                    :is-loading="isSubmitting"
                    :is-edit="userModal.isEdit"
                    :edit-data="userModal.editData"
                    @input="dataReset"
                    @user-handle="userHandle"/>
        <common-modal v-model="delModal.show"
                      :has-close="true"
                      :is-loading="isSubmitting"
                      @input="delIdReset"
                      @confirm-handle="delUser">
            <template v-slot:modal-message>確定刪除此會員?</template>
        </common-modal>
    </section>
</template>
<script>
import CommonModal from '@/components/Common/CommonModal';
import Pagination from '@/components/Common/Pagination';
import SearchBar from '@/components/Common/SearchBar';
import UserModal from '@/components/UserMgmt/UserModal';
import {mapState, mapGetters, mapActions} from 'vuex';

export default {
    name: 'UserMgmt',
    components: {
        CommonModal,
        Pagination,
        SearchBar,
        UserModal
    },
    computed: {
        ...mapState({
            isLoading: state => state.user.isLoading,
            userList: state => state.user.userList,
            pagination: state => state.user.pagination
        }),
        ...mapGetters({
            gymId: 'user/gymId'
        })
    },
    data() {
        return {
            isSubmitting: false,
            searchForm: {},
            userModal: {
                show: false,
                isEdit: false,
                editData: null
            },
            delModal: {
                show: false,
                delId: null
            }
        };
    },
    async mounted() {
        await this.initUserList();
    },
    methods: {
        ...mapActions('user', ['fetchUserList']),
        async initUserList(page = 1) {
            await this.fetchUserList({page: page, hasOwn: false});
        },
        // 觸發excel匯入
        importFileClick() {
            const permissionCheck = this.permissionCheck('匯入場館會員帳號');
            if(!permissionCheck) return;

            this.$refs.importFileButton.value = '';
            this.$refs.importFileButton.click();
        },
        // 會員匯入
        async importFile(e) {
            try {
                this.isSubmitting = true;
                const file = e.target.files[0];
                const formData = new FormData();
                formData.append('file', file);
                formData.append('gym_id', this.gymId);
                const res = await this.$http.post('/gymCustomerImport', formData);
                if(res.status === 200) {
                    this.toastSuccess('匯入成功');
                    this.searchForm = {};
                    await this.initUserList();
                }
            } catch(err) {
                const errMsg = err.response.data;
                if(errMsg.file && errMsg.file[0] === 'The file field must be a file of type: xlsx.') {
                    this.toastError('檔案格式錯誤，請稍後再試');
                } else if(errMsg.length !== 0) {
                    const errList = [];
                    _.forEach(errMsg, (item, index) => {
                        if(index > 4) return;
                        errList.push(item);
                    });
                    this.toastError(errList.join('、'));
                } else {
                    this.toastError('上傳失敗，請稍後再試');
                }
            } finally {
                this.isSubmitting = false;
            }
        },
        // 範例下載
        exportFileClick() {
            this.exportFile('/gymCustomerImportTemplateDownload', null, '匯入範例檔案.xlsx');
        },
        // 搜尋
        async searchHandle(searchData) {
            this.searchForm = _.cloneDeep(searchData);
            await this.fetchUserList({page: 1, keyword: this.searchForm.name, hasOwn: false});
        },
        // 分頁切換
        async pageHandler(targetPage) {
            await this.initUserList(targetPage);
        },
        // 開啟新增、編輯會員modal
        userModalHandle(data) {
            const permissionCheck = this.permissionCheck(data ? '編輯場館會員帳號' : '新增場館會員帳號');
            if(!permissionCheck) return;

            this.userModal.isEdit = data ? true : false;

            if(data) {
                this.userModal.editData = data;
            }
            this.userModal.show = true;
        },
        // 新增、編輯會員
        async userHandle(data) {
            try {
                this.isSubmitting = true;
                const formData = {
                    gym_id: this.gymId,
                    name: data.name,
                    birthday: data.birthday,
                    gender: data.gender,
                    height: data.height,
                    weight: data.weight,
                    dominant_hand: data.dominant_hand,
                    dominant_foot: data.dominant_foot,
                    ...(data.user_id ? {user_id: data.user_id} : {}),
                    ...(!this.userModal.editData ? {email: data.email} : {})
                };
                let res;
                if(this.userModal.isEdit) {
                    res = await this.$http.put('/gymCustomer', formData);
                } else {
                    res = await this.$http.post('/gymCustomer', formData);
                }
                if(res.status === 200) {
                    const successMsg = this.userModal.isEdit ? '更新成功' : '新增成功';
                    this.toastSuccess(successMsg);

                    const findUserIndex = _.findIndex(this.userList, item => item.user_id === this.userModal.editData?.user_id);
                    if(this.userModal.isEdit && findUserIndex !== -1) {
                        this.userList[findUserIndex] = {...this.userList[findUserIndex], ...formData};
                    } else {
                        this.searchForm = {};
                        await this.initUserList();
                    }
                    this.dataReset();
                }
            } catch(err) {
                this.toastError(`${this.userModal.editData ? '編輯' : '新增'}失敗，請稍後再試`);
            } finally {
                this.isSubmitting = false;
            }
        },
        // 開啟刪除會員modal
        delModalHandle(id) {
            const permissionCheck = this.permissionCheck('刪除場館會員帳號');
            if(!permissionCheck) return;

            this.delModal.delId = id;
            this.delModal.show = true;
        },
        async delUser() {
            try {
                this.isSubmitting = true;
                const res = await this.$http.delete(`/gymCustomer/${this.delModal.delId}`);
                if(res.status === 200) {
                    this.delModal.delId = null;
                    this.delModal.show = false;
                    this.searchForm = {};
                    await this.initUserList();
                }
            } catch(err) {
                this.toastError('刪除失敗，請稍後再試');
            } finally {
                this.isSubmitting = false;
            }
        },
        delIdReset() {
            this.delModal.delId = null;
        },
        dataReset() {
            this.userModal.isEdit = false;
            this.userModal.show = false;
            this.userModal.editData = null;
        }
    }
};
</script>
<style lang="scss" scoped>
@mixin mobile{
    @media(max-width: 767px){
        @content;
    }
}
:deep(.dropdown){
    .btn-dropdown{
        background-color: var(--orange-color);
        color:            white;
        &:hover{
            background-color: var(--orange-dark-color);
            color:            white;
        }
    }
    .dropdown-menu{
        padding: 0;
    }
    .dropdown-item{
        text-align: center;
        padding:    10px 10px 0 10px;
        &:active{
            background-color: var(--orange-color);
            color:            white;
        }
        &:last-child{
            padding-bottom: 10px;
        }
    }
    .file-control{
        display: none;
    }
}
.table{
    &.table-bordered{
        @include mobile{
            border: 0;
        }
    }
    thead{
        @include mobile{
            display: none;
        }
    }
    th:last-child{
        width: 165px;
    }
    tr{
        @include mobile{
            display:       block;
            margin-bottom: 30px;
            border:        1px solid #ccc;
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
    td{
        @include mobile{
            display: block;
            width:   100% !important;
            border:  0;
        }
        &:first-child{
            @include mobile{
                background-color: #CCC;
            }
        }
        &:before{
            @include mobile{
                content:       attr(data-label);
                position:      relative;
                display:       block;
                margin-bottom: 3px;
                font-weight:   bold;
            }
        }
    }
}
</style>
