<template>
    <b-modal v-model="show"
             centered
             no-close-on-backdrop
             @hide="closeModal">
        <template #modal-title>
            <p class="title-text">請選擇使用者</p>
        </template>
        <ul class="user-list">
            <template v-for="item in userData">
                <li v-if="item.gym_customer"
                    :key="item.gym_customer.user_id"
                    class="list-item">
                    <label class="user-label" :class="{'active':currentUser.includes(item.id)}">
                        {{ item.gym_customer.name }}
                        <input v-model="currentUser" type="checkbox" :value="item.id"/>
                    </label>
                </li>
            </template>
        </ul>
        <template #modal-footer>
            <button type="button" class="btn red-btn" @click="closeModal">關閉</button>
            <button type="button" class="btn green-btn" @click="confirmHandler" :disabled="isLoading">匯出</button>
        </template>
    </b-modal>
</template>
<script>
import CommonSelect from '../Common/CommonSelect';

export default {
    name: 'UserSelectModal',
    props: {
        value: {
            type: Boolean,
            default: true
        },
        userData: {
            type: Array,
            default: []
        },
        isLoading: Boolean
    },
    components: {
        CommonSelect
    },
    watch: {
        value(newVal) {
            this.show = newVal;
            this.currentUser = [];
        }
    },
    data() {
        return {
            show: this.value,
            currentUser: []
        };
    },
    methods: {
        closeModal() {
            this.userId = null;
            this.$emit('input', false);
        },
        async confirmHandler() {
            this.$emit('export-handler', this.currentUser);
        }
    }
};
</script>
<style lang="scss" scoped>
.user-list{
    .list-item{
        margin-bottom: 10px;
        &:last-child{
            margin-bottom: 0;
        }
    }
    .user-label{
        width:         100%;
        border-radius: 40px;
        padding:       5px;
        cursor:        pointer;
        font-size:     20px;
        text-align:    center;
        &:hover,
        &.active{
            background-color: var(--primary-color);
            color:            white;
            font-weight:      bold;
        }
        input[type="checkbox"]{
            opacity: 0;
            width:   0;
            height:  0;
        }
    }
}
</style>
