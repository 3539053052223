import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

import user from './modules/user';

export default new Vuex.Store({
    state: {
        pageLoading: false
    },
    getters: {},
    mutations: {
        ['UPDATE_PAGE_LOADING'](state, pageLoading) {
            state.pageLoading = pageLoading;
        }
    },
    actions: {},
    modules: {
        user
    }
});
