import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import {BootstrapVue} from 'bootstrap-vue';
import _ from 'lodash';
import moment from 'moment';
import CxltToastr from 'cxlt-vue2-toastr';
import VeeValidate from 'vee-validate';
import '@/config/mixin';
import http from '@/config/http';
import DatePicker from 'vue2-datepicker';
import vue2DatepickerSet from 'vue2-datepicker/locale/zh-tw';
import ClipLoader from 'vue-spinner/src/ClipLoader';
import '@/assets/css/main.scss';

Vue.config.productionTip = false;

vue2DatepickerSet.formatLocale.firstDayOfWeek = 7; // vue2-datepicker語言及星期設定

Vue.prototype._ = _;
Vue.prototype.$moment = moment;
Vue.prototype.$http = http;

Vue.component('ClipLoader', ClipLoader);
Vue.use(BootstrapVue);
Vue.use(CxltToastr, {
    position: 'top center',
    timeOut: 5000,
    showMethod: 'slideInUp',
    hideMethod: 'slideOutUp'
})
Vue.use(VeeValidate);
Vue.use(DatePicker);

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
