<template>
    <div v-if="searchTypes.length !== 0" class="search-bar">
        <template v-for="item in searchList">
            <div v-if="searchTypes.includes(item.labelName)" class="form-group">
                <label v-if="item.type !== 'select'" class="form-label">
                    <span class="label-text">{{ item.labelName }}</span>
                    <input v-if="item.type === 'text'" v-model="form[item.formName]" type="text" class="form-control" placeholder="請輸入"/>
                    <date-picker v-else v-model="form[item.formName]" type="month" placeholder="請選擇"/>
                </label>
                <div v-else class="form-label">
                    <span class="label-text">{{ item.labelName }}</span>
                    <common-select v-model="form[item.formName]"
                                   class="select-control"
                                   display-label="name"
                                   find-key="id"
                                   :is-allow-empty="false"
                                   :select-list="competitionList"/>
                </div>
            </div>
        </template>
        <div class="form-group text-right">
            <button type="button" class="btn primary-btn px-4" @click="searchHandle">搜尋</button>
        </div>
    </div>
</template>
<script>
import CommonSelect from '@/components/Common/CommonSelect';

export default {
    name: 'SearchBar',
    props: {
        isLoading: Boolean,
        competitionList: Array,
        searchForm: Object,
        searchTypes: {
            type: Array,
            default: () => {
                return [];
            }
        }
    },
    components: {
        CommonSelect
    },
    watch: {
        searchForm: {
            handler: function(newVal) {
                this.form = _.cloneDeep(newVal);
            },
            deep: true
        }
    },
    data() {
        return {
            form: {},
            searchList: [
                {
                    type: 'text',
                    labelName: '裝備MAC',
                    formName: 'mac_address'
                },
                {
                    type: 'date',
                    labelName: '月份',
                    formName: 'year_month'
                },
                {
                    type: 'text',
                    labelName: '姓名',
                    formName: 'name'
                },
                {
                    type: 'text',
                    labelName: '使用者名稱',
                    formName: 'user_name'
                },
                {
                    type: 'select',
                    labelName: '賽事',
                    formName: 'competition_id'
                }
            ]
        };
    },
    mounted() {
        this.form = _.cloneDeep(this.searchForm);
    },
    methods: {
        searchHandle() {
            this.$emit('search', this.form);
        }
    }
};
</script>
<style lang="scss" scoped>
@mixin mobile{
    @media (max-width: 767px){
        @content;
    }
}
.search-bar{
    display:    flex;
    flex-wrap:  wrap;
    column-gap: 10px;
    flex-grow:  1;
    @include mobile{
        width: 100%;
    }
    .form-group{
        @include mobile{
            width: 100%;
        }
    }
    .form-label{
        flex-direction: row;
        align-items:    center;
        column-gap:     10px;
        row-gap:        5px;
        .label-text{
            white-space:   nowrap;
            margin-bottom: 0;
        }
    }
    .form-control,
    .select-control{
        width: 250px;
        @include mobile{
            width: 100%;
        }
    }
}
</style>
