<template>
    <div v-if="!pageLoading" class="wrapper">
        <div class="wrapper">
            <div class="wrapper-content">
                <div class="d-none d-lg-block login-bg"></div>
                <div class="p-3 p-sm-5 login-content">
                    <router-view></router-view>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
    computed: {
        ...mapState({
            pageLoading: state => state.pageLoading
        })
    }
}
</script>

<style lang="scss" scoped>
.login-bg{
    width:               55%;
    background-image:    url('@/assets/images/login_bg.svg');
    background-size:     cover;
    background-position: center;
    background-repeat:   no-repeat;
}
.login-content{
    width:       45%;
    display:     flex;
    align-items: center;
    @media(max-width: 991px){
        width:               100%;
        background-image:    url('@/assets/images/login_bg.svg');
        background-size:     cover;
        background-position: center;
        background-repeat:   no-repeat;
    }
}
</style>
