<template>
    <header>
        <div class="header d-flex flex-wrap align-items-center justify-content-between">
            <h1 class="header-title">{{ gym ? gym.name : '智聯羽球場館' }}後臺管理</h1>
            <button type="button" class="btn logout-btn ml-auto" @click="logoutModalOpen">
                登 出
            </button>
        </div>
        <nav class="header-nav">
            <router-link v-for="item in localLinks"
                         :key="`link_${item.urlName}`"
                         class="nav-item"
                         :class="{'active': $route.path === `/admin/${item.urlName}`}"
                         :to="`/admin/${item.urlName}`">
                {{ item.name }}
            </router-link>
        </nav>
        <common-modal v-model="modalShow"
                      :is-loading="isLoading"
                      @confirm-handle="logout">
            <template v-slot:modal-message>確認登出系統?</template>
            <template v-slot:modal-btn>確認</template>
        </common-modal>
    </header>
</template>

<script>
import CommonModal from './CommonModal.vue';
import {mapGetters} from 'vuex';

export default {
    name: "HeaderView",
    components: {
        CommonModal
    },
    computed: {
        ...mapGetters({
            gym: 'user/gym'
        })
    },
    data() {
        return {
            isLoading: false,
            localLinks: [
                {
                    urlName: 'sensor',
                    name: '裝備綁定'
                },
                {
                    urlName: 'user',
                    name: '會員管理'
                },
                {
                    urlName: 'competition-schedule',
                    name: '賽程配對'
                }
            ],
            modalShow: false
        }
    },
    methods: {
        logoutModalOpen() {
            this.modalShow = true;
        },
        async logout() {
            try {
                this.isLoading = true;
                const res = await this.$http.get('/logout');
                if(res.status === 200) {
                    this.toastSuccess('登出成功')
                    this.modalShow = false;
                    // await this.syncCsrfToken();
                    localStorage.removeItem('token');
                    this.$store.commit('user/UPDATE_USER', null);
                    await this.$router.push('/login');
                }
            } catch(err) {
                this.$store.commit('user/UPDATE_USER', null);
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.header{
    width:            100%;
    background-color: var(--primary-color);
    padding:          15px 70px;
    gap:              10px;
    @media(max-width: 767px){
        padding: 15px;
    }
}
.header-title{
    color:          white;
    font-weight:    bold;
    font-size:      24px;
    letter-spacing: 4px;
    text-align:     left;
}
.logout-btn{
    color:            #0E524E;
    background-color: rgba(255, 255, 255, 0.8);
    font-size:        20px;
    font-weight:      bold;
    line-height:      1;
    padding:          8px 30px;
    &:hover{
        background-color: white;
    }
}
.header-nav{
    width:            100%;
    display:          flex;
    justify-content:  center;
    background-color: #F3F3F3;
    overflow-x:       auto;
}
.nav-item{
    position:        relative;
    display:         flex;
    align-items:     center;
    justify-content: center;
    margin-bottom:   0;
    min-width:       160px;
    color:           #AEAEAE;
    font-weight:     bold;
    font-size:       20px;
    border:          0;
    text-decoration: none;
    padding:         10px 0;
    &:before{
        content:    '';
        position:   absolute;
        width:      0;
        height:     5px;
        bottom:     0px;
        left:       0;
        transition: .3s;
    }
    &.active,
    &:hover{
        color: var(--primary-color);
        &:before{
            width:            100%;
            background-color: var(--primary-color);
        }
    }
}
</style>
