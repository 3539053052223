import mixin from '@/config/mixin';
import http from '@/config/http';

export default {
    namespaced: true,
    state: {
        user: null,
        isLoading: false,
        userList: [],
        pagination: {
            currentPage: 1,
            totalPage: 1
        }
    },
    getters: {
        isLogin: state => {
            return !!state.user ? true : false;
        },
        roles: state => {
            return !!state.user ? state.user.roles : null;
        },
        permissions: state => {
            return !!state.user ? state.user.permissions : null;
        },
        gym: state => {
            return !!state.user ? state.user.gym_employee[0] : null;
        },
        gymId: state => {
            return !!state.user ? state.user.gym_employee[0].pivot.gym_id : null;
        }
    },
    actions: {
        async fetchUserList({commit, state}, {page = null, keyword = null, hasOwn = true} = {}) {
            try {
                commit('UPDATE_IS_LOADING', true);
                const res = await http.get('/gymCustomer', {
                    params: {
                        gym_id: state.user.gym_employee[0].pivot.gym_id,
                        keyword: keyword && keyword !== '' ? keyword : null,
                        ...(page ? {per_page: 10, page: page} : {})
                    }
                });
                if(res.status === 200) {
                    const resData = res.data.data;
                    commit('UPDATE_USER_LIST', page ? hasOwn ? resData.data : _.filter(resData.data, item => item.user_id !== state.user?.id) : resData);
                    if(page) {
                        commit('UPDATE_PAGINATION', {currentPage: page, totalPage: resData.last_page});
                    }
                }
            } catch(err) {
                mixin.toastError('使用者讀取失敗，請稍後再試');
            } finally {
                commit('UPDATE_IS_LOADING', false);
            }
        }
    },
    mutations: {
        ['UPDATE_USER'](state, data) {
            state.user = data;
        },
        ['UPDATE_IS_LOADING'](state, data) {
            state.isLoading = data;
        },
        ['UPDATE_USER_LIST'](state, data) {
            state.userList = data;
        },
        ['UPDATE_PAGINATION'](state, data) {
            state.pagination = data;
        }
    }
};