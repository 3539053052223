<template>
    <b-modal v-model="show"
             centered
             @hide="closeModal">
        <template #modal-title>
            <p class="title-text">
                <span v-if="!isEdit">新增</span><span v-else>編輯</span>使用者
            </p>
        </template>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">姓名</span>
                <input v-model="form.name"
                       type="text"
                       name="name"
                       class="form-control" :class="{'error-control':errors.has('name:required') || errors.has('name:max')}"
                       placeholder="請輸入姓名"
                       v-validate="'required|max:5'"/>
            </div>
            <div class="form-label">
                <span class="label-text">出生日期</span>
                <date-picker v-model="form.birthday" value-type="format" format="YYYY-MM-DD"/>
            </div>
        </div>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">Email(帳號)</span>
                <input v-model="form.email"
                       type="text"
                       name="email"
                       class="form-control" :class="{'error-control':errors.has('email:required') || errors.has('email:mail')}"
                       placeholder="請輸入Email"
                       v-validate="'required|email'"
                       :disabled="isEdit"/>
                <span v-show="errors.has('email:email')" class="error-text">格式錯誤</span>
            </div>
            <div class="form-label">
                <span class="label-text">性別</span>
                <common-select v-model="form.gender"
                               :select-list="gender"
                               find-key="id"
                               display-label="name"
                               :is-search="false"/>
            </div>
        </div>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">身高(公分)</span>
                <input v-model.number="form.height"
                       type="number"
                       name="height"
                       class="form-control"
                       :class="{'error-control': errors.has('height:decimal')}"
                       placeholder="請輸入身高"
                       v-validate="'decimal:2'"/>
            </div>
            <div class="form-label">
                <span class="label-text">體重(公斤)</span>
                <input v-model.number="form.weight"
                       type="number"
                       name="weight"
                       class="form-control"
                       :class="{'error-control': errors.has('weight:decimal')}"
                       placeholder="請輸入體重"
                       v-validate="'decimal:2'"/>
            </div>
        </div>
        <div class="control-row">
            <div class="form-label">
                <span class="label-text">慣用手</span>
                <common-select v-model="form.dominant_hand"
                               :select-list="handList"
                               find-key="id"
                               display-label="name"/>
            </div>
            <div class="form-label">
                <span class="label-text">慣用腳</span>
                <common-select v-model="form.dominant_foot"
                               :select-list="footList"
                               find-key="id"
                               display-label="name"/>
            </div>
        </div>
        <template #modal-footer>
            <button type="button" class="btn gray-btn" @click="closeModal">取消</button>
            <button type="button" class="btn green-btn" @click="userHandle" :disabled="isLoading">
                <clip-loader v-if="isLoading" class="loading" color="white" size="15px"/>
                <span v-else>
                    <template v-if="!isEdit">新增</template>
                    <template v-else>儲存</template>
                </span>
            </button>
        </template>
    </b-modal>
</template>
<script>
import CommonSelect from '../Common/CommonSelect';
import ClipLoader from 'vue-spinner/src/ClipLoader';

export default {
    name: 'UserModal',
    props: {
        isLoading: Boolean,
        value: {
            type: Boolean,
            default: true
        },
        isEdit: Boolean,
        editData: {
            type: Object,
            default: {}
        }
    },
    components: {
        CommonSelect,
        ClipLoader
    },
    watch: {
        value(newVal) {
            this.resetValidate();
            this.show = newVal;
        },
        editData: {
            handler(newVal) {
                if(newVal) {
                    this.form = _.cloneDeep(newVal);
                    this.form.email = this.form.user ? this.form.user.email : null
                } else {
                    this.resetForm();
                }
            },
            deep: true
        }
    },
    data() {
        return {
            show: this.value,
            gender: [
                {
                    id: 1,
                    name: '男'
                },
                {
                    id: 2,
                    name: '女'
                },
                {
                    id: 3,
                    name: '其他'
                }
            ],
            handList: [
                {
                    id: 1,
                    name: '右手'
                },
                {
                    id: 2,
                    name: '左手'
                }
            ],
            footList: [
                {
                    id: 1,
                    name: '右腳'
                },
                {
                    id: 2,
                    name: '左腳'
                }
            ],
            form: {}
        }
    },
    methods: {
        closeModal() {
            this.resetForm();
            this.$emit('input', false);
        },
        async userHandle() {
            const isPass = await this.validate(true);
            if(!isPass) return;

            this.$emit('user-handle', this.form)
        },
        resetForm() {
            this.form = {}
        }
    }
}
</script>
<style lang="scss" scoped>
.loading{
    padding: 4px 8px;
}
.form-label{
    width: calc(100% / 2 - 5px);
}
</style>
