<template>
    <div class="wrapper">
        <header-view/>
        <div v-if="!pageLoading" class="wrapper-content">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
        <clip-loader v-else class="loading" color="gray" size="30px"></clip-loader>
    </div>
</template>

<script>
import HeaderView from "@/components/Common/HeaderView";
import {mapState} from 'vuex';
import ClipLoader from 'vue-spinner/src/ClipLoader';

export default {
    components: {
        ClipLoader,
        HeaderView
    },
    name: "AdminLayout",
    computed: {
        ...mapState({
            pageLoading: state => state.pageLoading
        })
    }
}
</script>
<style lang="scss" scoped>
.wrapper-content{
    padding: 30px;
}
</style>
