<template>
    <b-pagination
        v-model="pagination.currentPage"
        :per-page="1"
        :total-rows="pagination.totalPage"
        first-number
        last-number
        @change="changePage">
        <template #prev-text><</template>
        <template #next-text>></template>
    </b-pagination>
</template>
<script>
export default {
    name: 'Pagination',
    props: {
        value: Number,
        pagination: Object
    },
    methods: {
        changePage(e) {
            this.$emit('input', e);
            this.$emit('page-handle');
        }
    }
}
</script>
<style lang="scss" scoped>
.pagination{
    justify-content: flex-end;
    :deep(.page-item){
        margin-right: 5px;
        &:last-child{
            margin-right: 0;
        }
        .page-link{
            border:        0;
            border-radius: 5px;
            color:         #A7A7A7;
            padding:       8px 10px;
            line-height:   1;
        }
        &.active .page-link,
        .page-link:hover{
            background-color: var(--primary-color);
            color:            white;
        }
    }
}
</style>
