export default [
    {
        path: '/login',
        component: require('@/views/Login').default,
        meta: {layout: 'AuthLayout'}
    },
    {
        path: '/admin',
        component: require('@/Empty').default,
        meta: {auth: true},
        children: [
            {
                path: 'sensor',
                component: require('@/views/EquipmentSensorMgmt.vue').default,
                meta: {permissions: ['查看擁有者裝備清單']}
            },
            {
                path: 'user',
                component: require('@/views/UserMgmt.vue').default,
                meta: {permissions: ['查看場館會員']}
            },
            {
                path: 'competition-schedule',
                component: require('@/views/CompetitionScheduleMgmt.vue').default
            }
        ]
    },
    {path: '*', redirect: '/login'}
];