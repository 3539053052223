<template>
    <div id="app">
        <component :is="layout"/>
    </div>
</template>
<script>
import AuthLayout from '@/layouts/AuthLayout';
import AdminLayout from '@/layouts/AdminLayout';

export default {
    name: 'App',
    components: {
        AuthLayout,
        AdminLayout
    },
    watch: {
        $route(to) {
            if(to.meta.layout !== undefined) {
                this.layout = to.meta.layout;
            } else {
                this.layout = 'AdminLayout';
            }
        }
    },
    data() {
        return {
            layout: null
        };
    }
};
</script>