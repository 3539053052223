<template>
    <div class="login-box">
        <h1 class="login-title">智聯羽球場館後臺管理</h1>
        <form class="form">
            <div class="form-group">
                <label class="form-label flex-row align-items-center mb-0">
                    <img src="@/assets/images/icons/user.svg" class="form-icon" alt="帳號Icon"/>
                    <input v-model="form.email"
                           type="text"
                           name="email"
                           class="form-control border-0 px-1"
                           placeholder="email"
                           v-validate="'required|email'"/>
                </label>
                <p v-show="errors.has('email:required')" class="is-danger">請輸入帳號</p>
                <p v-show="errors.has('email:email')" class="is-danger">請輸入正確的email格式</p>
            </div>
            <div class="form-group">
                <label class="form-label flex-row align-items-center mb-0 ">
                    <img src="@/assets/images/icons/lock.svg" class="form-icon" alt="密碼Icon"/>
                    <input v-model="form.password"
                           type="password"
                           name="password"
                           class="form-control border-0 px-1"
                           placeholder="password"
                           @keydown.enter="submit"
                           v-validate="'required'"/>
                </label>
                <p v-show="errors.has('password:required')" class="is-danger">請輸入密碼</p>
            </div>
            <button type="button" class="btn primary-btn w-100 font-weight-bold py-2" @click="submit">
                <clip-loader v-if="isLoading" class="loading p-0" color="white" size="22px"/>
                <span v-else>登　入</span>
            </button>
        </form>
    </div>
</template>

<script>

export default {
    name: "Login",
    data() {
        return {
            form: {},
            isLoading: false
        };
    },
    methods: {
        async submit() {
            try {
                const isPass = await this.validate();
                if(!isPass) return;

                this.isLoading = true
                const res = await this.$http.post('/login', this.form);
                const data = res.data;
                if(res.status === 200) {
                    localStorage.setItem('token', data.access_token);
                    await this.$router.push('/admin/sensor')
                } else {
                    localStorage.removeItem('token')
                }
            } catch(err) {
                const errMsg = err.response.data.error;
                if(errMsg === 'Invalid credentials') {
                    this.toastError('登入失敗，請確認帳號密碼是否有誤')
                } else {
                    this.toastError('登入失敗，請稍後再試')
                }
            } finally {
                this.isLoading = false;
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.login-box{
    padding-left:     70px;
    width:            550px;
    background-color: white;
    @media (max-width: 991px){
        padding: 30px;
        width:   100%;
    }
}
.login-title{
    text-align:    center;
    font-weight:   bold;
    margin-bottom: 40px;
    font-size:     32px;
}
.form{
    .form-group{
        margin-bottom: 25px;
    }
    .form-label{
        padding:       10px 20px 10px 10px;
        border:        1px solid #B9B9B9;
        border-radius: 5px;
    }
    .form-control{
        font-size: 20px;
    }
    .form-icon{
        padding-right: 10px;
        margin-right:  20px;
        border-right:  2px solid #B9B9B9;
    }
    .primary-btn{
        font-size: 22px;
    }
}
</style>
